import { type Uuid } from './common'
import { type InvoiceProduct } from './invoice-product'

export enum InvoiceStatus {
  New = 0,
  Pending = 1,
  PartiallyPaid = 2,
  Paid = 3,
  AmlInProgress = 11,
  AmlFinished = 12,
  AmlWaitingForUserAction = 13,
  TransferInProgress = 21,
  RefundInProgress = 31,
  PartiallyRefunded = 38,
  Refunded = 39,
  Error = 97,
  Canceled = 98,
  Successful = 99
}

export interface Invoice {
  readonly uuid: Uuid
  initial_amount: number
  initial_currency: string
  initial_network: string
  readonly status: InvoiceStatus
  order_num: string
  pay_amount: number
  pay_token: string
  pay_network: string
  readonly paid_amount: number
  readonly products: InvoiceProduct[]
  expires_at: string
  created_at: string
  updated_at: string
  readonly processed: boolean
}

export interface Filter
{
  name: string,
  value: any
}
